import React, { useContext } from 'react';
import _ from 'lodash';

import type { PromotionBannerSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/PromotionBannerSlice/PromotionBannerSlice';
import type { ConfigContextType } from '../../../context/config.context';

import { PromotionBannerSlice } from '@ww-digital/web-palette-react/dist/components/Slice/PromotionBannerSlice/PromotionBannerSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { ConfigContext } from '../../../context/config.context.ts';

interface PromotionBannerSliceContainerProps {
  daCategory: string;
  slice: PromotionBannerSliceProps;
}

export const PromotionBannerSliceContainer = ({
  daCategory,
  slice,
}: PromotionBannerSliceContainerProps): JSX.Element => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const processJSONKey = translations.PROCESS_JSON === 'true';

  const sliceView = _.cloneDeep(slice);
  sliceView.processJSON = processJSONKey;

  if (sliceView.cta) {
    sliceView.cta.attributes = {};
    sliceView.cta.attributes['da-category'] = daCategory;
    sliceView.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
      'promotion_banner_cta',
      sliceView.cta.text,
    );
    sliceView.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
      sliceView.cta.text,
    );
  }

  if (sliceView.floatingCTA?.cta) {
    sliceView.floatingCTA.cta.attributes = {};
    sliceView.floatingCTA.cta.attributes['da-category'] = daCategory;
    sliceView.floatingCTA.cta.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'promotion_banner_floatingCTA',
        sliceView.floatingCTA.cta.text,
      );
    sliceView.floatingCTA.cta.attributes['da-label'] =
      AnalyticsUtility.formatLabel(sliceView.floatingCTA.cta.text);
  }

  if (sliceView.stickyBanner?.cta) {
    sliceView.stickyBanner.cta.attributes = {};
    sliceView.stickyBanner.cta.attributes['da-category'] = daCategory;
    sliceView.stickyBanner.cta.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'promotion_banner_stickyCTA',
        sliceView.stickyBanner.cta.text,
      );
    sliceView.stickyBanner.cta.attributes['da-label'] =
      AnalyticsUtility.formatLabel(sliceView.stickyBanner.cta.text);
  }

  return <PromotionBannerSlice {...sliceView} />;
};
